import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IComboBase } from '../interfaces/base/base.interface';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  private apiUrl = `${environment.apiUrl}/employees`;

  constructor(private http: HttpClient) {}

  comboEmployee(companyId: string): Observable<IComboBase[]> {
    return this.http.get<IComboBase[]>(`${this.apiUrl}/combo`, {
      params: { companyId: companyId },
    });
  }
}
