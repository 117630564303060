import { Component } from '@angular/core';
import { monthOptions } from '../utils/helpers';
import { PaymentSlipService } from './services/payment-slip.service';
import { PaymentSlipResponseDTO } from '../interfaces/payment-slips.interface';
import { CompanyService } from '../services/company.service';
import { IComboBase } from '../interfaces/base/base.interface';

@Component({
  selector: 'app-payment-slips',
  templateUrl: './payment-slips.component.html',
  styleUrls: ['./payment-slips.component.scss'],
})
export class PaymentSlipsComponent {
  dataSource: PaymentSlipResponseDTO[] = [];
  mostrar: boolean = false;

  // Opciones del combo
  monthOptions: IComboBase[] = monthOptions;

  // Término de búsqueda
  searchTerm = '';

  // Mes seleccionado
  selectedMonth: IComboBase | null = null;

  constructor(
    private paymentSlipService: PaymentSlipService,
    private companyService: CompanyService
  ) {}

  tabs: IComboBase[] = [];
  displayedColumns = [
    { key: 'numDocumento', header: 'Nº Documento' },
    { key: 'importe', header: 'Importe' },
    { key: 'fecha', header: 'Fecha' },
    { key: 'empleado', header: 'Empleado' },
  ];

  actionButtons = [
    {
      icon: 'pi pi-pencil',
      color: '#0288d1',
      tooltip: 'Editar',
      action: (row: any) => {
        console.log('Editar:', row);
      },
    },
    {
      icon: 'pi pi-eye',
      color: '#43a047',
      tooltip: 'Ver',
      action: (row: any) => {
        console.log('Ver:', row);
      },
    },
  ];

  ngOnInit(): void {
    this.companyService.comboCompany().subscribe({
      next: (data) => {
        this.tabs = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de empresas:', err);
      },
    });

    this.filterData(); // Cargar datos iniciales
  }

  onNewRegister(): void {
    this.mostrar = true;
    console.log('click en nuevo');
  }

  onMonthChange(selected: IComboBase): void {
    this.selectedMonth = selected;
    this.filterData(); // Aplicar filtro cuando cambia el mes
  }

  onSearch(query: string): void {
    this.searchTerm = query;
    this.filterData(); // Aplicar filtro cuando cambia la búsqueda
  }

  filterData(): void {
    const filters = {
      search: this.searchTerm,
      mesNumber: this.selectedMonth?.key || null, // Si no hay mes seleccionado, enviar null
    };

    this.paymentSlipService.getAllPaymentSlips(filters).subscribe({
      next: (data: PaymentSlipResponseDTO[]) => {
        this.dataSource = data;
        console.log('Datos filtrados:', this.dataSource);
      },
      error: (err: any) => {
        console.error('Error al obtener los datos:', err);
      },
    });
  }

  onTabSelected(tabKey: string): void {
    console.log('Tab seleccionado:', tabKey);
    // Puedes agregar lógica adicional aquí, como actualizar los datos mostrados
  }
}