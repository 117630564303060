<p-dialog
  header="Nuevo Registro"
  [(visible)]="visible"
  [modal]="true"
  [closable]="false"
  [style]="{ width: '50%', height: '100%' }"
>
  <div class="flex flex-column gap-3 mb-3">
    <!-- Empresa -->
    <div class="field">
      <label for="empresa" class="font-semibold">Empresa</label>
      <p-autoComplete
        id="empresa"
        [(ngModel)]="selectedEmpresa"
        [suggestions]="filteredOptions"
        (completeMethod)="filterOptions($event, 'empresa')"
        (input)="onChangeInput($event, 'empresa')"
        [dropdown]="true"
        [forceSelection]="true"
        [placeholder]="'Selecciona una empresa...'"
        (onSelect)="onSelect($event, 'empresa')"
        [style]="{ width: '100%' }"
        [field]="'text'"
      ></p-autoComplete>
      <div *ngIf="!selectedEmpresa" style="color: red; font-size: 0.875rem; margin-top: 5px;">
        Este campo es obligatorio.
      </div>
    </div>
    <br />
    <!-- Colaborador -->
    <div class="field">
      <label for="colaborador" class="font-semibold">Colaborador</label>
      <p-autoComplete
        id="colaborador"
        [(ngModel)]="selectedColaborador"
        [suggestions]="filteredOptions"
        (completeMethod)="filterOptions($event, 'colaborador')"
        (input)="onChangeInput($event, 'colaborador')"
        [dropdown]="true"
        [forceSelection]="true"
        [placeholder]="'Selecciona un colaborador...'"
        (onSelect)="onSelect($event, 'colaborador')"
        [style]="{ width: '100%' }"
        [field]="'text'"
      ></p-autoComplete>
      <div *ngIf="!selectedColaborador" style="color: red; font-size: 0.875rem; margin-top: 5px;">
        Este campo es obligatorio.
      </div>
    </div>
    <br />
    <!-- Moneda -->
    <div class="field">
      <label for="moneda" class="font-semibold">Moneda</label>
      <p-autoComplete
        id="moneda"
        [(ngModel)]="selectedMoneda"
        [suggestions]="filteredOptions"
        (completeMethod)="filterOptions($event, 'moneda')"
        (input)="onChangeInput($event, 'moneda')"
        [dropdown]="true"
        [forceSelection]="true"
        [placeholder]="'Selecciona una moneda...'"
        (onSelect)="onSelect($event, 'moneda')"
        [style]="{ width: '100%' }"
        [field]="'text'"
      ></p-autoComplete>
      <div *ngIf="!selectedMoneda" style="color: red; font-size: 0.875rem; margin-top: 5px;">
        Este campo es obligatorio.
      </div>
    </div>
    <br />
    <!-- Monto -->
    <div class="field">
      <label for="monto" class="font-semibold">Monto</label>
      <br />
      <p-inputNumber
        id="monto"
        [(ngModel)]="monto"
        (ngModelChange)="onChangeInput($event, 'monto')"
        [min]="0"
        [max]="10000"
        [step]="1"
        [placeholder]="'Ingresa un monto...'"
        [style]="{ width: '100%' }"
      ></p-inputNumber>
      <div *ngIf="!monto" style="color: red; font-size: 0.875rem; margin-top: 5px;">
        Este campo es obligatorio.
      </div>
    </div>
    <br />
    <!-- Carga de archivo PDF -->
    <div class="field">
      <label for="documento" class="font-semibold"
        >Cargar Documento (PDF)</label
      >
      <p-fileUpload
        text="file"
        [accept]="'application/pdf'"
        [maxFileSize]="10000000"
        (onSelect)="onChangeFile($event)"
        [style]="{ width: '100%' }"
      ></p-fileUpload>
      <div *ngIf="!currentFile" style="color: red; font-size: 0.875rem; margin-top: 5px;">
        Este campo es obligatorio.
      </div>
    </div>
  </div>
  <br />

  <div style="display: flex; justify-content: center; gap: 10px; margin-top: 10px;">
  <!-- Botón Aceptar -->
  <button
    type="button"
    pButton
    label="Registrar"
    [disabled]="isDisabled"
    (click)="onRegister()"
    [style]="{ width: '25%' }"
  ></button>
  <!-- Botón Cancelar -->
  <button
    type="button"
    pButton
    label="Cancelar"
    (click)="onCancel()"
    [style]="{ width: '25%' ,
    backgroundColor: '#f44336',
    border: 'none' }"
  ></button>
</div>

</p-dialog>
