<mat-tab-group class="tab-group" (selectedIndexChange)="onTabChange($event)">
  <mat-tab *ngFor="let tab of tabs" [label]="tab.text"></mat-tab>
</mat-tab-group>
<br />
<div class="content-container">
  <!-- Tabla -->
  <div class="table-wrapper">
    <div class="table-container">
      <table mat-table [dataSource]="dataSourceInstance" class="mat-elevation-z8">
        <!-- Columnas dinámicas -->
        <ng-container
          *ngFor="let column of displayedColumns"
          [matColumnDef]="column.key"
        >
          <th mat-header-cell *matHeaderCellDef>{{ column.header }}</th>
          <td mat-cell *matCellDef="let element">{{ element[column.key] }}</td>
        </ng-container>

        <!-- Columna de acciones -->
        <ng-container matColumnDef="acciones" *ngIf="actionButtons.length > 0">
          <th mat-header-cell *matHeaderCellDef>Acciones</th>
          <td mat-cell *matCellDef="let row">
            <button
              *ngFor="let button of actionButtons"
              class="custom-icon-button"
              [ngStyle]="{ color: button.color }"
              matTooltip="{{ button.tooltip }}"
              (click)="onActionClick(row, button.action)"
            >
              <i class="{{ button.icon }}"></i>
            </button>
          </td>
        </ng-container>

        <!-- Header -->
        <tr mat-header-row *matHeaderRowDef="columnKeys"></tr>
        <!-- Filas -->
        <tr mat-row *matRowDef="let row; columns: columnKeys"></tr>
      </table>
    </div>
    <mat-paginator
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
    ></mat-paginator>
  </div>

  <!-- Card de previsualización -->
  <div class="preview-card" *ngIf="showPreviewCard">
    <div class="preview-content" *ngIf="selectedDocument">
      <iframe
        [src]="selectedDocument"
        width="100%"
        height="100%"
        frameborder="0"
        title="PDF Preview"
      ></iframe>
    </div>
    <div class="preview-content" *ngIf="!selectedDocument">
      Previsualización no disponible
    </div>
  </div>
</div>