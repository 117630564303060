import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PageAccionesComponent } from './page-acciones/page-acciones.component';
import { PageContainerComponent } from './page-container/page-container.component';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { FileUploadModule } from 'primeng/fileupload';



@NgModule({
  declarations: [
    // Componentes reutilizables
    PageHeaderComponent,
    PageAccionesComponent,
    PageContainerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatTooltipModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    AutoCompleteModule,
    InputNumberModule,
    FileUploadModule
  ],
  exports: [
    // Componentes reutilizables
    PageHeaderComponent,
    PageAccionesComponent,
    PageContainerComponent,
    // Módulos necesarios
    CommonModule,
    FormsModule,
    MatTooltipModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    AutoCompleteModule,
    DialogModule,
    InputNumberModule,
    FileUploadModule
  ],
})
export class SharedModule {}
