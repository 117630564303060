import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { IComboBase } from '../../interfaces/base/base.interface';

@Component({
  selector: 'app-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
})
export class PageContainerComponent implements OnInit {
  @Input() tabs:  IComboBase[] = [];
  @Output() tabSelected = new EventEmitter<string>();
  @Input() displayedColumns: { key: string; header: string }[] = [];
  @Input() actionButtons: {
    icon: string;
    color: string;
    tooltip: string;
    action: (row: any) => void;
  }[] = [];
  @Input() set dataSource(data: any[]) {
    this.dataSourceInstance.data = data;
  }

  // Nuevo Input para controlar la visibilidad del card
  @Input() showPreviewCard: boolean = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  columnKeys: string[] = [];
  dataSourceInstance = new MatTableDataSource<any>();
  selectedDocument: string | null = null; // URL del documento seleccionado para previsualización

  ngOnInit(): void {
    this.columnKeys = this.displayedColumns.map((c) => c.key);
    if (this.actionButtons.length > 0) {
      this.columnKeys.push('acciones');
    }
  }

  ngAfterViewInit(): void {
    this.dataSourceInstance.paginator = this.paginator;
  }

  onActionClick(row: any, action: (row: any) => void): void {
    action(row);
  }

  /**
   * Simula la previsualización de un documento PDF.
   * En un caso real, selecciona la URL del PDF del objeto row.
   */
  previewDocument(row: any): void {
    // Ejemplo: obtener URL del PDF desde la fila
    this.selectedDocument = row.pdfUrl || null;
  }


  onTabChange(index: number): void {
    const selectedTab = this.tabs[index];
    if (selectedTab) {
      this.tabSelected.emit(selectedTab.key); // Emitir la clave (`key`) del tab seleccionado
    }
  }
}
