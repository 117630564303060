import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PaymentSlipResponseDTO } from '../../interfaces/payment-slips.interface';

@Injectable({
  providedIn: 'root',
})
export class PaymentSlipService {
  private apiUrl = `${environment.apiUrl}/payment-slips`;

  constructor(private http: HttpClient) {}

  getAllPaymentSlips(filter: { search: string }): Observable<PaymentSlipResponseDTO[]> {
    return this.http.post<PaymentSlipResponseDTO[]>(this.apiUrl, filter);
  }

  createPaymentSlip(data: FormData): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/register', data);
  }
}
