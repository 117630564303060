<div class="boletas-container">
  <!-- Título y subtítulo -->
  <app-page-header
    [title]="'Boletas'"
    [subtitle]="'Administra tus boletas y facturas'"
  ></app-page-header>

  <!-- Botones, combo y barra de búsqueda -->
  <app-page-acciones
    [buttonText]="'Nuevo Registro'"
    [buttonIcon]="'pi pi-plus'"
    [buttonColor]="'#04a563'"
    (buttonClick)="onNewRegister()"
    [comboOptions]="monthOptions"
    (comboSelectedChange)="onMonthChange($event)"
    [searchQuery]="searchTerm"
    (searchQueryChange)="onSearch($event)"
    [comboPlaceholder]="'Seleccione un mes...'"
  >
  </app-page-acciones>

  <!-- Tabs y tabla dinámica -->
  <app-page-container
    [tabs]="tabs"
    [displayedColumns]="displayedColumns"
    [dataSource]="dataSource"
    [actionButtons]="actionButtons"
    [showPreviewCard]="true"
    (tabSelected)="onTabSelected($event)"
  ></app-page-container>

  <!-- Modal del registro -->
  <app-registro-modal
    [(visible)]="mostrar"
  ></app-registro-modal>
</div>
