import { NgModule } from '@angular/core';
import { PaymentSlipsRoutingModule } from './payment-slips-routing.module';
import { PaymentSlipsComponent } from './payment-slips.component';
import { SharedModule } from '../shared/shared.module';
import { RegistroModalComponent } from './components/registro-modal/registro-modal.component';

@NgModule({
  declarations: [
    PaymentSlipsComponent,
    RegistroModalComponent
  ],
  imports: [
    PaymentSlipsRoutingModule,
    SharedModule,
  ],
})
export class PaymentSlipsModule {}
